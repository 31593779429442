<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Tipe Outlet</label>
                    <Dropdown id="modul_id" dataKey="modul_id" v-model="filters.modul_id" :loading="loadingDropdownModul"
                        :options="dataDropdownModul"
                        optionLabel="modul_name" optionValue="modul_id" placeholder="Pilih Tipe Outlet"
                        :filter="true" :showClear="false" @filter="searchDropdownModul($event, 'filter')"
                     />
                </div>
                <!-- <div class="field col-12 md:col-6 lg:col-3">
                    <label>Salesman</label>
                    <Dropdown id="salesman_id" dataKey="salesman_id" v-model="filters.salesman_id" :loading="loadingDropdownSalesman"
                        :options="dataDropdownSalesman"
                        optionLabel="salesman_name" optionValue="salesman_id" placeholder="Pilih Salesman"
                        :filter="true" :showClear="false" @filter="searchDropdownModul($event, 'filter')"
                     />
                </div> -->
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Salesman</label>
                    <Dropdown id="userid" dataKey="userid" v-model="filters.userid" :loading="loadingDropdownUser"
                        :options="dataDropdownUser"
                        optionLabel="username" optionValue="userid" placeholder="Pilih Salesman"
                        :filter="true" :showClear="true" @filter="searchDropdownUser($event, 'filter')"
                     />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownModul: false,
            // loadingDropdownSalesman: false,
            loadingDropdownUser: false,

            // dataDropdown
            dataDropdownModul: null,
            // dataDropdownSalesman: null,
            dataDropdownUser: null,

            // filter
            filters: {
                modul_id: null,
                // salesman_id: null,
                userid: null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
            minDate: null,
        }
    },
    mounted() {
        this.filters.modul_id = 1;
        // this.filters.salesman_id = 1;
    },
    created(){
        this.searchDropdownModul('');
        // this.searchDropdownSalesman('');
        this.searchDropdownUser('');
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownModul(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownModul = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-modul',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownModul = res.data.data;
                            this.loadingDropdownModul = false;
                        } else if (purpose == null) {
                            this.dataDropdownModul = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        // searchDropdownSalesman(event, purpose, valueEdit) {
        //     setTimeout(() => {

        //         if (valueEdit) {
        //             this.$refs.dsalesman.filterValue = valueEdit;
        //         }

        //         if (purpose == "filter") {
        //             this.loadingDropdownSalesman = true;
        //         }

        //         this.axios({
        //             method: 'GET',
        //             url: process.env.VUE_APP_ROOT_API + 'web/select2/master-salesman',
        //             params: {
        //                 "search": valueEdit ? valueEdit : event.value,
        //             }
        //         })
        //             .then(res => {

        //                 if (purpose == "filter") {
        //                     this.dataDropdownSalesman = res.data.data;
        //                     this.loadingDropdownSalesman = false;
        //                 } else if (purpose == null) {
        //                     this.dataDropdownSalesman = res.data.data;
        //                 }
        //             })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     }, 250);
        // },
        searchDropdownUser(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.duserhp.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/user-hp',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownUser = res.data.data;
                            this.loadingDropdownUser = false;
                        } else if (purpose == null) {
                            this.dataDropdownUser = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

