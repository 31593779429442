<template>
    <Dialog v-model:visible="detailItemDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }" header="Data Detail" :modal="true" :maximizable="true">
        <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="username" @sort="onSort($event)">
            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <div>
                        <!-- <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                        <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" /> -->
                        {{item.users_hp.username}}-{{item.survey_judul}}
                    </div>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                    </span>
                </div>
            </template>
            <Column field="number" header="No." :style="{width:'20px'}" >
                <template #body="slotProps">
                {{ slotProps.data.number }}
                </template>
            </Column>
            <Column field="qz_desc" header="Pertanyaan">
                <template #body="slotProps">
                {{ slotProps.data.qz_desc }}
                </template>
            </Column>
            <Column field="jawaban_final" header="Jawaban">
                <template #body="slotProps">
                {{ slotProps.data.jawaban_final }}
                </template>
            </Column>
            <Column field="url_photo" header="Photo">
                <template #body="slotProps">
                    <div v-if="slotProps.data.url_photo">
                        <Image :src="slotProps.data.url_photo" :alt="slotProps.data.url_photo" width="100" preview />
                    </div>
                    <div v-else>
                        <Image src="/no_image.jpg" alt="No Image" width="100" preview />
                    </div>

                </template>
            </Column>
            <template #empty>
                No data found.
            </template>
            <template #loading>
                Loading data. Please wait.
            </template>
            <template #footer>
                In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
            </template>
        </DataTable>

        <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
            <template>
                {{totalItemsCount}}
            </template>
        </Paginator>

        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        </template>

    </Dialog>
</template>

<script>

import moment from "moment";
import { numberingDatatable } from "../../../utils/helpers";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            loading: false,

            //detail user
            detailItemDialog: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {   
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        detailItemDialog: {
            handler() {
                this.clearDataTable();
                setTimeout(() => {
                    if(this.detailItemDialog == true){
                        this.getDataTable();
                    }
                }, 200);
            },
        }
    },
    methods:{
        formatDate(value) {
            if (value) {
                return moment.utc(value).format("DD-MM-YYYY");
            }
        },
        hideDialog() {
            this.detailItemDialog = false;
        },
        // DATATABLE
        getDataTable(){

            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/report-survey-visit/detail',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "visit_id": this.item.visit_id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        clearDataTable(){
            this.data= null;
            this.dataTable= null;
            this.rows= 10;
            this.search= null;
            this.offset= null;
            this.field= null;
            this.sort= null;
            this.page= null;
            this.totalItemsCount= 0;
        }
    }
}
</script>